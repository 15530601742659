<template>
  <div class="page-customer">
    <div class="card card-custom card-stretch gutter-b customer-container">
      <!--begin::Header-->
      <div class="card-header">
        <!--begin::card-title-->
        <div class="card-title">
          <h3 class="card-label">
            <div class="d-flex align-items-center">
              <span class="title font-weight-bold text-dark mr-3">개인정보취급방침</span>
            </div>
          </h3>
        </div>
      </div>
        <!--end::card-title-->
      <!--begin::Body-->
      <div class="card-body">
        이용약관 <br />
        제 1 조 (목적)<br />
        본 약관은 회원(본 약관에 동의한 자를 말합니다 이하 "회원"이라고 합니다.)이 플랜티팜 주식회사 (이하 "회사"라고 합니다)가 제공하는 티팜온(t-farmon)서비스 (이하 "서비스"라고 합니다)를 이용함에 있어 회사와 회원의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.<br />
        <br />
        제 2조 (약관의 명시, 효력 및 개정)<br />
        ① 회사는 본 약관의 내용을 회원이 알 수 있도록 서비스 페이지(https://www.t-farmon.com)에 게시함으로써 이를 공지합니다.<br />
        ② 회사는 필요하다고 판단하는 경우 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br />
        ③ 회사가 약관을 개정할 경우에는 개정약관 및 개정약관의 시행일자와 개정사유를 명시하여 그 시행일자 15일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하며, 개정된 약관은 그 시행일에 효력이 발생합니다. 단, 개정 내용이 회원에게 불리한 경우에는 그 시행일자 30일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하거나 팝업화면을 게시하는 등 이용자가 충분히 인지할 수 있는 합리적으로 가능한 방법으로 공지하며, 개정된 약관은 그 시행일에 효력이 발생됩니다.<br />
        ④ 회사가 전항에 따라 회원에게 통지하면서 공지일로부터 개정약관 시행일까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 회원의 거부의 의사표시가 없는 경우에는 변경된 약관에 동의한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 제14조 제1항의 규정에 따라 이용계약을 해지할 수 있습니다.<br />
        <br />
        제 3 조 (약관 동의 및 서비스 이용계약의 체결)<br />
        ① 회사와 회원 간의 서비스 이용계약은 이용자가 본 약관 내용에 대해 동의하고 서비스 이용신청을 하면 회사가 그 신청을 승낙함으로써 성립합니다.<br />
        ② 회사는 이용자가 서비스 회원가입 페이지의 “동의함” 버튼을 클릭하거나 이용자가 본 서비스의 이용을 시작한 경우 본 약관에 동의한 것으로 간주합니다.<br />
        ③ 회사는 이용자가 필수사항 등을 성실히 입력하여 회원가입을 완료하였을 때에는 지체 없이 이를 승낙하여야 합니다. 단, 회사는 아래 각 호에 해당하는 경우에는 회원가입을 유보할 수 있습니다.<br />
        1. 서비스의 설비용량에 현실적인 여유가 없는 경우<br />
        2. 서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우<br />
        ④ 회사는 아래 각 호에 해당하는 경우에는 본 서비스 회원가입에 대한 승낙을 하지 않거나 사후에 회원가입을 해지할 수 있습니다.<br />
        1. 이용자가 서비스 이용신청 시 허위의 정보를 기재하거나 회사가 요청하는 사항을 기재하지 아니한 경우<br />
        2. 제11조 제2항에 의해 회사가 본 서비스 이용계약을 해지했던 회원이 다시 서비스 이용신청을 하는 경우, 단 회사로부터 재가입 승낙을 받은 경우는 예외로 함<br />
        3. 이용자의 귀책사유로 인하여 승낙이 불가능하거나 관련 법령 등에 위반하여 서비스 이용신청을 하는 경우<br />
        ⑤ 본 조 제3항 및 제4항에 따라 회사가 회원가입에 대해 승낙을 유보하거나 승낙을 하지 않을 경우, 회사는 그 사실을 이용자에게 알리도록 합니다.<br />
        ⑥ 본 서비스 이용계약은 회사가 신청절차 상에서 회원가입 완료를 표시한 시점에 성립합니다.<br />
        <br />
        제 4 조 (개인정보의 보호 및 관리)<br />
        ① 회사는 서비스를 제공하기 위하여 회원으로부터 서비스 이용에 필요한 개인정보를 수집할 수 있습니다.<br />
        ② 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 회원의 개인정보의 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보 처리방침을 적용합니다.<br />
        ③ 회사는 회사의 귀책 없이 회원의 귀책사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.<br />
        <br />
        제 5 조 (서비스의 제공 및 이용)<br />
        ① 회사가 제공하는 서비스의 종류는 아래 각 호로 합니다.<br />
        1. 교육 프로그램 제공을 위해 회원이 직접 개설하는 사이트<br />
        2. 사이트 운영을 위해 제공되는 오피스<br />
        3. 학습을 위해 제공되는 강의실<br />
        4. 튜터스기어 서비스의 원활한 제공을 위해 함께 제공되는 모바일웹과 앱 애플리케이션<br />
        5. 각 호 1~4의 서비스에 부가하여 회사가 회원에게 제공하는 제휴 파트너 서비스 등 일체의 서비스<br />
        ② 회원은 별도의 비용이 청구되지 않는 무료 서비스와 회사가 정한 비용을 결제한 이후 사용할 수 있는 유료 서비스를 선택하여 이용할 수 있습니다.<br />
        ③ 회원은 유료 서비스 이용을 희망하는 경우 회사가 미리 정한 방법에 따라 이용 요금을 결제합니다.<br />
        ④ 회원이 회사에서 정한 서비스 사용 가이드를 준수하지 않거나, 환불, 이용기간 만료 등 유료 서비스 이용 기간이 종료되는 경우 서비스 제공이 중지됩니다.<br />
        ⑤ 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 회사는 서비스 설비의 정기점검 등의 사유로 일정 기간 동안 서비스 제공을 일시 중지하거나 서비스 제공 시간을 제한할 수 있으며, 이 경우 회사는 회원에 대해 그 사유를 사전에 통지합니다. 단, 회사는 사전 고지가 불가능한 긴급한 사유가 있는 경우 사후에 통지할 수 있습니다.<br />
        <br />
        제 6 조 (서비스의 변경, 중단, 일시 중지)<br />
        ① 회사는 서비스의 일부 또는 전부를 회사의 사업 계획 및 서비스 운영정책에 따라 수정·변경 및 중단할 수 있으며, 이에 대하여 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.<br />
        ② 회사는 서비스용 설비 점검·보수·공사 및 기간통신사업자의 전기통신 서비스의 중지, 서비스 이용의 폭주나 국가비상사태 등을 사유로 서비스 제공에 장애가 발생한 경우 그 사유가 해소될 때까지 서비스를 일시 중지할 수 있습니다.<br />
        ③ 회사는 본 조에 따른 서비스의 변경·중단·일시 중지의 사유가 발생한 경우, 서비스를 통해 공지하는 등의 방법으로 회원에게 통지합니다.<br />
        제 7 조 (사이트 개설 및 사이트의 이용)<br />
        ① 회원은 도메인을 직접 입력하여 본인의 사이트를 직접 개설할 수 있습니다.<br />
        ② 사이트는 제공되는 서비스 범위에 따라 무료 서비스와 유료 서비스로 구분됩니다.<br />
        ③ 회원은 본인이 직접 개설한 사이트에 대해 권리를 보유하며, 회사가 사이트의 권리를 취득하지는 않습니다. 단, 회원이 개설한 사이트에서 다루어지는 정보 중 다른 회원에게 일반적으로 공개되는 것에 한하여 회사가 적절하다고 판단한 범위 내에서 서비스와 프로모션에 이용할 수 있는 권리를 회사에 대해 무상으로 기한과 지역의 제한 없이 허락하는 것으로 간주합니다.<br />
        ④ 회원은 사이트 이용을 통해 발생한 다른 회원 정보와 서비스 이용 통계 등에 대한 권리를 보유하며, 회사가 이 권리를 취득하지는 않습니다. 단, 회사가 필요하고 적절하다고 판단하는 범위 내에서 서비스와 프로모션에 이용할 수 있는 권리를 회사에 대해 무상으로 기한과 지역의 제한 없이 허락하는 것으로 간주합니다.<br />
        <br />
        제 8 조 (콘텐츠 권리의 귀속 및 콘텐츠의 이용)<br />
        ① 회원은 본인이 작성한 콘텐츠에 대해 보유하는 권리를 기존과 다름없이 보유하며, 회사가 이 권리를 취득하지는 않습니다. 단, 회원이 작성한 콘텐츠 중 다른 회원에게 일반적으로 공개되는 것에 한하여 회사가 필요하고 적절하다고 판단한 범위 내에서 서비스와 프로모션에 이용할 수 있는 권리를 회사에 대해 무상으로 기한과 지역의 제한 없이 허락하는 것으로 간주합니다.<br />
        ② 회원은 자신이 서비스에 게시한 콘텐츠 등을 회사가 국내ㆍ외에서 다음 각 호의 목적으로 사용하는 것을 허락합니다.<br />
        1. 서비스(서비스가 제3자가 운영하는 사이트 또는 미디어의 일정 영역 내에 입점하는 형태로 제공되는 경우 포함) 내에서 콘텐츠 등의 복제·전송·전시 및 우수 콘텐츠를 서비스 화면에 노출하기 위하여 콘텐츠의 내용 변경 없이 크기를 변환하거나 단순화하는 등의 방식으로 수정하는 것<br />
        2. 회사가 운영하는 다른 서비스에서 콘텐츠를 복제·전송 또는 전시하는 것. 다만, 회원이 명시적으로 복제·전송 또는 전시에 동의하지 아니한 경우에는 그러하지 않음<br />
        3. 회사의 서비스를 홍보하기 위한 목적으로 미디어·통신사 등에 콘텐츠의 전부 또는 일부를 보도·방영하게 하는 것. 이 경우 회사는 회원의 개별 동의 없이 미디어·통신사 등에게 회원정보를 제공하지 않음<br />
        ③ 회사는 전항 이외의 방법으로 회원의 콘텐츠 등을 이용하고자 하는 경우에는 사전에 회원의 동의를 얻습니다.<br />
        ④ 회원이 서비스에 콘텐츠를 게재하는 것은 다른 회원이 콘텐츠를 서비스 내에서 사용하거나 회사가 검색결과로 사용하는 것을 허락한 것으로 간주합니다. 다만, 회원은 서비스 내의 관리기능을 통하여 콘텐츠의 공개옵션 설정 등의 조치를 취할 수 있습니다.<br />
        ⑤ 회사는 서비스의 정책 또는 회사가 운영하는 서비스 간 통합 등의 사유로 콘텐츠의 게재위치를 변경·이전할 수 있으며, 이 경우 사전에 공지합니다.<br />
        ⑥ 회원은 서비스 이용을 통해 발생한 콘텐츠 이용 통계 등에 대한 권리를 보유하며, 회사가 이 권리를 취득하지는 않습니다. 단, 회사가 필요하고 적절하다고 판단하는 범위 내에서 서비스와 프로모션에 이용할 수 있는 권리를 회사에 대해 무상으로 기한과 지역의 제한 없이 허락하는 것으로 간주합니다.<br />
        <br />
        제 9 조 (서비스의 관리 및 임시조치)<br />
        ① 회원의 사이트와 콘텐츠 등 이용하는 서비스가 관련 법령에 위반되는 내용을 포함하는 경우, 회사는 해당 관련 법령이나 적법한 권리자의 요청에 따라 해당 서비스 등에 대한 게시중단 및 삭제 등의 조치를 취할 수 있습니다.<br />
        ② 회사는 회원이 이용하는 서비스에 회사의 이용약관에 위반되는 사유가 있는 것으로 확인된 경우, 이용약관에 따라 해당 서비스에 대해 조치를 취할 수 있습니다.<br />
        ③ 관련 법령의 규정에 의해 다른 회원의 서비스가 본인의 사생활을 침해하거나 명예를 훼손하는 등 권리를 침해 받은 회원 또는 제3자(이하 “삭제 등 신청인”이라 합니다)는 그 침해사실을 소명하여 회사에 해당 서비스 등의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 해당 서비스 등의 권리 침해 여부를 판단할 수 없거나 회사자 간의 다툼이 예상되는 경우 해당 서비스 등에 대한 접근을 임시적으로 차단하는 조치(이하 “임시조치”라 합니다)를 최장 30일까지 취합니다.<br />
        ④ 전항에 의해 본인의 서비스가 임시 조치된 회원(이하 “게시자”라 합니다)은 임시조치기간 중 회사에 해당 서비스 등을 복원해 줄 것을 요청(이하 “재게시 청구”라 합니다)할 수 있으며, 회사는 임시 조치된 콘텐츠의 명예훼손 등을 판단하여 서비스의 복원 여부를 결정합니다. 재게시 청구가 없는 경우 해당 서비스는 임시조치 기간 만료 이후 삭제됩니다.<br />
        ⑤ 회사는 회원이 이용중인 서비스가 사생활 침해 또는 명예훼손 등 제3자의 권리를 침해한다고 인정하는 경우 회원 또는 제3자의 신고가 없는 경우에도 임시조치(이하 “임의의 임시조치”라 합니다)를 취할 수 있습니다. 임의의 임시 조치된 서비스의 처리 절차는 본 조 제3항 후단 및 제4항의 규정에 따릅니다.<br />
        ⑥ 회원의 서비스 등으로 인한 법률상 이익 침해를 근거로, 다른 회원 또는 제3자가 회원 또는 회사를 대상으로 하여 민·형사상의 법적 조치를 취하는 경우, 회사는 동 법적 조치의 결과인 법원의 확정판결이 있을 때까지 관련 서비스에 대한 접근을 잠정적으로 제한할 수 있습니다. 서비스의 접근 제한과 관련한 법적 조치의 소명, 법원의 확정 판결에 대한 소명 책임은 서비스에 대한 조치를 요청하는 자가 부담합니다.<br />
        <br />
        제 10 조 (회사의 의무)<br />
        ① 회사는 안정적인 서비스 제공을 위해 이에 필요한 설비를 유지·점검하며 문제가 발생한 경우 복구 등의 조치를 이행합니다.<br />
        ② 회사는 회원의 이메일로 회원이 수신에 동의하지 아니한 영리 목적의 광고성 전자우편을 발송하지 아니합니다.<br />
        <br />
        제 11 조 (회원의 의무)<br />
        ① 회원은 아래 각 호에 해당하는 행위를 하여서는 안됩니다.<br />
        1. 회원정보에 허위내용을 등록하는 행위<br />
        2. 회사 또는 제3자의 저작권, 상표권, 특허권 등의 지적 재산권, 명예권, 프라이버시권, 기타 법령상 또는 계약상의 권리를 침해하는 행위<br />
        3. 다른 회원의 계정을 도용하여 부당하게 서비스를 이용하는 행위<br />
        4. 공공질서 또는 미풍양속을 저해할 우려가 있는 행위<br />
        5. 과도하게 폭력적인 표현, 노골적인 성적 표현, 인종, 국적, 신조, 성별, 사회적 신분과 가문 등에 관하여 차별하는 표현, 자살, 자상 행위, 약물 남용을 유인 또는 조장하는 표현, 기타 반사회적인 내용을 포함하고 타인에게 불쾌감을 주는 표현을 투고 또는 송신하는 행위.<br />
        5. 반사회적인 세력에 의한 이익 공여와 기타 협력 행위<br />
        6. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해·파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드·파일·프로그램을 포함하고 있는 자료를 게시하거나 다른 회원에게 발송하는 행위<br />
        7. 스토킹(stalking). 스팸성 댓글의 게재 등 다른 회원의 정상적인 서비스 이용을 방해하는 행위<br />
        8. 다른 회원의 개인정보를 동의 없이 수집·저장·공개하는 행위<br />
        9. 광고 또는 선전 등 영리 목적으로 서비스를 이용하는 행위<br />
        10. 회사가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 하는 행위<br />
        11. 기타 부적당하다고 회사가 판단하는 행위<br />
        ② 회사는 회원이 전항 각 호의 행위를 하는 경우 해당 서비스를 삭제 또는 임시 조치할 수 있고 회원의 서비스 이용을 제한하거나 그 사유가 중대한 경우 일방적으로 본 계약을 해지할 수 있습니다.<br />
        ③ 회원이 본 조 제2항 각 호의 행위를 함으로써 회사에 손해가 발생한 경우, 회사는 해당 회원에 대해 책임에 대한 보상을 청구할 수 있습니다.<br />
        ④ 본 서비스 내에서 회사의 관여 없이 회원 간 이루어지는 일체의 행위(거래 행위 포함)와 관련하여 발생하는 모든 의무와 책임은 해당 회원에게 있으며, 회사는 그 내용에 대하여 책임을 지지 않습니다.<br />
        <br />
        제 12 조 (서비스 이용의 제한 및 중지)<br />
        ① 회사는 아래 각 호에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.<br />
        1. 회원이 회사의 서비스 운영을 고의 또는 과실로 방해하는 경우<br />
        2. 회원이 제10조의 회원의 의무를 위반한 경우<br />
        3. 기타 중대한 사유로 인하여 회사가 해당 회원에 대해 서비스 제공을 지속하는 것이 상당하지 않다고 판단되는 경우<br />
        ② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 회원에게 알립니다.<br />
        <br />
        제 13 조 (계정의 관리책임)<br />
        회원은 본인의 계정 관리에 대한 책임을 부담하며, 본인 계정을 제3자가 무단 이용하는 등 회원의 귀책사유로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 회사의 고의∙과실로 인하여 야기된 경우에는 회사가 책임을 부담합니다.<br />
        <br />
        제 14 조 (양도금지)<br />
        회원의 서비스 받을 권리는 이를 양도 내지 증여의 대상으로 하거나 질권의 목적으로 활용할 수 없습니다.<br />
        <br />
        제 15 조 (이용계약의 해지)<br />
        ① 회원이 본 서비스 이용계약을 해지하고자 하는 때에는 회사가 제공하는 서비스 내의 회원탈퇴 기능을 이용하여 탈퇴를 요청할 수 있습니다. 회사는 탈퇴를 요청한 시점에서 불가피한 사정이 없는 한 즉시 탈퇴요청을 처리합니다.<br />
        ② 본 이용 계약이 해지된 경우 회원의 콘텐츠 등은 삭제됩니다. 단, 다른 회원의 정상적인 서비스 이용에 필요하다고 회사가 판단하는 경우 콘텐츠 등은 보존 될 수 있습니다.<br />
        ③ 전항의 규정에 의하여 보존된 서비스 등은 이미 제공된 서비스 유지를 목적으로 하며, 기타 제 3자에게 제공되거나 다른 서비스에 게재되지 않습니다.<br />
        <br />
        제 16 조 (면책조항)<br />
        ① 회사는 다음 각 호의 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.<br />
        1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br />
        2. 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우<br />
        3. 회원의 귀책사유로 서비스 이용에 장애가 발생한 경우<br />
        4. 제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 서비스를 제공하는 것이 어려운 경우<br />
        ② 회사는 회원이 작성하여 서비스에 게재된 정보 및 자료의 신뢰도나 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.<br />
        <br />
        제 17 조 (회원에 대한 통지)<br />
        ① 회사가 회원에 대한 통지를 하는 경우, 본 약관에 별도 규정이 없는 한 회원이 회사에 제공한 이메일 주소로 통지할 수 있습니다.<br />
        ② 회사가 회원 전체에게 통지를 하는 경우, 7일 이상 회사의 서비스 페이지 또는 서비스 내 공지사항 등에 게시함으로써 전항의 통지에 갈음할 수 있습니다.<br />
        <br />
        제 18 조 (약관의 해석)<br />
        본 약관에 명시되지 않은 사항에 대해서는 회사와 회원 간 합의하여 결정하고, 합의가 되지 않는 경우 관련 법령 또는 상관례에 따릅니다.<br />
        <br />
        제 19 조 (준거법 및 재판관할)<br />
        ① 본 약관은 대한민국 법에 의하여 규정되고 이행되며, 회사와 회원 간에 제기된 소송에는 대한민국 법을 적용합니다.<br />
        ② 서비스 이용과 관련하여 회사와 회원 간에 발생한 분쟁은 서울중앙지방법원 또는 민사소송법 상의 관할법원에 제기합니다.<br />
        <br />
        본 약관은 2021년 01월 28일부터 적용됩니다.<br />
        <br />
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>
<script>
export default {
  name: "privacy",
}
</script>
